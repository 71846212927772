import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useFormik } from 'formik';

import { ButtonWithArrow } from '@/components/Button';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

import successImage from '../../../../assets/svg/success.svg';

import TextField from './components/TextField/TextField';
import TextArea from './components/TextArea/TextArea';
import { ContactFormData, ContactFormProps } from './ContactForm.types';
import { validationSchema } from './ContactForm.helpers';

function ContactForm({ isContactsForm, save }: ContactFormProps) {
  const [isFormSend, setFormSend] = useState(false);

  const { values, errors, touched, handleChange, handleSubmit, isSubmitting } =
    useFormik<ContactFormData>({
      initialValues: {
        name: '',
        email: '',
        message: '',
      },
      validationSchema,
      onSubmit: async (values, { setSubmitting }) => {
        setSubmitting(true);

        try {
          await save(values);
          setFormSend(true);
        } catch (error) {
          console.error(error);
        }

        setSubmitting(false);
      },
    });

  return isFormSend ? (
    <Message isContactsForm={isContactsForm}>
      <Image
        src={successImage}
        src2x={successImage}
        srcWebp={successImage}
        srcWebp2x={successImage}
      />
      <Title isContactsForm={isContactsForm}>Thank you!</Title>
      <Subtitle isContactsForm={isContactsForm}>
        We will contact you shortly
      </Subtitle>
    </Message>
  ) : (
    <Form
      onSubmit={handleSubmit}
      isContactsForm={isContactsForm}
      noValidate
      autoComplete="off"
    >
      <TextField
        placeholder="Name"
        id="name"
        name="name"
        type="text"
        value={values.name}
        onChange={handleChange}
        errorMessage={errors.name && touched.name ? errors.name : undefined}
      />

      <TextField
        placeholder="Email"
        id="email"
        name="email"
        type="email"
        value={values.email}
        onChange={handleChange}
        errorMessage={errors.email && touched.email ? errors.email : undefined}
      />

      <TextArea
        placeholder="Message"
        id="message"
        name="message"
        type="text"
        value={values.message}
        onChange={handleChange}
        errorMessage={
          errors.message && touched.message ? errors.message : undefined
        }
      />

      <Actions isContactsForm={isContactsForm}>
        <ButtonWithArrow title="Send" isLoading={isSubmitting} />
      </Actions>
    </Form>
  );
}

export default ContactForm;

const Form = styled.form<{
  isContactsForm: ContactFormProps['isContactsForm'];
}>`
  position: relative;

  ${({ isContactsForm }) =>
    isContactsForm &&
    css`
      & > input,
      > textarea {
        background: #f9f9f9;
      }

      margin-top: 39px;
    `}
`;

const Actions = styled.div<{
  isContactsForm: ContactFormProps['isContactsForm'];
}>`
  margin-top: 68px;

  ${({ isContactsForm }) =>
    isContactsForm &&
    css`
      margin-top: 43px;

      & > button {
        background: red;
      }

      & > button:hover {
        background: #bd0202;
      }
    `};
`;

const Message = styled.div<{
  isContactsForm: ContactFormProps['isContactsForm'];
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.laptop(
    css`
      align-items: flex-start;
    `
  )}

  ${media.tablet(css`
    justify-content: center;
    align-items: center;
  `)}
  
  ${media.mobile(css`
    margin-top: 50px;
  `)}

  ${({ isContactsForm }) =>
    isContactsForm &&
    css`
      margin-top: 80px;

      ${media.laptop(
        css`
          align-items: flex-end;
        `
      )}

      ${media.tablet(css`
        align-items: center;
      `)}
    `}
`;

const Image = styled(Picture)`
  position: relative;

  img {
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
  }
`;

const Title = styled.p<{
  isContactsForm: ContactFormProps['isContactsForm'];
}>`
  margin-top: 30px;
  color: black;
  font-size: 35px;
  line-height: 40px;
  text-align: center;

  ${media.laptop(
    css`
      font-size: 31px;
      line-height: 36px;
    `
  )}

  ${({ isContactsForm }) =>
    isContactsForm &&
    css`
      color: white;
    `}
`;

const Subtitle = styled.p<{
  isContactsForm: ContactFormProps['isContactsForm'];
}>`
  color: #ababab;
  margin-top: 27px;
  font-size: 27px;
  line-height: 32px;
  text-align: center;

  ${media.laptop(
    css`
      font-size: 23px;
      line-height: 28px;
    `
  )}

  ${({ isContactsForm }) =>
    isContactsForm &&
    css`
      color: white;
    `}
`;
