import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { ButtonWithArrow } from '@/components/Button';
import Picture from '@/components/Picture';
import SectionHeader from '@/components/SectionHeader';
import { useHomeData } from '@/modules/Home/Home.hooks';
import { media } from '@/utils/mixin';

function Location() {
  const { locationBackground, locationTitle, locationButtonTitle } =
    useHomeData();

  return (
    <Component>
      <Background
        src={locationBackground.url}
        src2x={locationBackground.url_2x}
        srcWebp={locationBackground.url_webp}
        srcWebp2x={locationBackground.url_webp_2x}
      />
      <ContentContainer>
        <Header>
          <SectionHeader title={locationTitle ?? ''} />
        </Header>

        <Actions>
          <ButtonWithArrow
            title={locationButtonTitle ?? ''}
            buttonLinkMode={true}
            to="/contacts"
          />
        </Actions>
      </ContentContainer>
    </Component>
  );
}

export default Location;

const Component = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const Background = styled(Picture)`
  position: absolute !important;
  padding-top: 40%;
  width: 100%;

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${media.tablet(css`
    width: 118%;
  `)}

  ${media.mobile(css`
    width: 142%;
  `)}
`;

const Header = styled.div`
  position: relative;
  margin-top: 107px;

  &:first-child div div p strong :nth-child(1) {
    font-weight: normal;
    color: #00843d;
  }

  &:first-child div div p strong :nth-child(2) {
    font-weight: normal;
    color: red;
  }

  ${media.tablet(css`
    margin-top: 150px;

    & > div > div {
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }
  `)}

  ${media.mobile(css`
    margin-top: 75px;

    & > div > div {
      font-size: 22px;
      line-height: 33px;
    }
  `)}
`;

const Actions = styled.div`
  position: relative;
  margin: 607px 0 197px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.mobile(css`
    margin: 260px 0 150px 0;
  `)}
`;
