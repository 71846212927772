import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import Picture from '@/components/Picture';
import { useWholesaleData } from '@/modules/Wholesale/Wholesale.hooks';
import { media } from '@/utils/mixin';

function ExchangeTraded() {
  const { exchangeTradedImage, exchangeTradedTitle } = useWholesaleData();

  return (
    <Component>
      <Background
        src={exchangeTradedImage.url}
        src2x={exchangeTradedImage.url_2x}
        srcWebp={exchangeTradedImage.url_webp}
        srcWebp2x={exchangeTradedImage.url_webp_2x}
      />
      <ContentContainer>
        <Content>
          <Text>{exchangeTradedTitle}</Text>
        </Content>
      </ContentContainer>
    </Component>
  );
}

export default ExchangeTraded;

const Component = styled.section`
  position: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: black;

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba(196, 196, 196, 0) 11.21%,
      rgba(196, 196, 196, 0.33) 27.5%,
      #c4c4c4 68.68%,
      rgba(196, 196, 196, 0.1) 100%
    );
    opacity: 0.25;
  }
`;

const Background = styled(Picture)`
  position: absolute !important;
  width: 100%;

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  ${media.tablet(css`
    height: 50%;
    margin-top: -100px;
  `)}

  ${media.mobile(css`
    margin-top: -200px;
  `)}
`;

const Content = styled.div`
  position: relative;
  margin: 150px 0 225px 0;

  ${media.tablet(css`
    margin: 50px 0 175px 0;
  `)}

  ${media.mobile(css``)}
`;

const Text = styled.p`
  position: relative;
  font-size: 40px;
  line-height: 60px;
  color: white;
  text-align: center;

  ${media.tablet(css`
    font-size: 32px;
    line-height: 48px;
  `)}

  ${media.mobile(css`
    line-height: 40px;
    text-align: left;
  `)}
`;
