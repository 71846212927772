import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import SectionHeader from '@/components/SectionHeader';
import { useSourcingActivityData } from '@/modules/SourcingActivity/SourcingActivity.hooks';
import { media } from '@/utils/mixin';

import EasyStepsCard from './Card';

function EasySteps() {
  const { easyStepsTitle, easyStepsItems } = useSourcingActivityData();

  return (
    <Component>
      <ContentContainer>
        <Header>
          <SectionHeader title={easyStepsTitle ?? ''} />
        </Header>

        <Cards>
          {easyStepsItems.map(
            ({ title, description, image, cardNumber }, index: number) => (
              <EasyStepsCard
                cardNumber={'0' + (index + 1)}
                title={title ?? ''}
                description={description ?? ''}
                image={image}
                whiteCard={index === 1}
                key={index}
              />
            )
          )}
        </Cards>
      </ContentContainer>
    </Component>
  );
}

export default EasySteps;

const Component = styled.section`
  background: white;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 100px;

  ${media.tablet(css`
    margin-top: 115px;
  `)}

  ${media.mobile(css`
    margin-top: 100px;
  `)}
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin: 100px 0 50px 0;

  ${media.tablet(css`
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
  `)}
`;
