import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import ContentContainer from '@/components/ContentContainer';
import SectionHeader from '@/components/SectionHeader';
import OurValueCard from '@/modules/Home/components/OurValueCard';
import { useSourcingActivityData } from '@/modules/SourcingActivity/SourcingActivity.hooks';
import { media } from '@/utils/mixin';

import OurFeaturesSlider from './OurFeaturesSlider';

function OurFeatures() {
  const { ourFeaturesTitle, ourFeaturesItems } = useSourcingActivityData();
  const mobileMedia = useMedia('(max-width: 768px)');

  return (
    <Component>
      <ContentContainer>
        <Header>
          <SectionHeader title={ourFeaturesTitle ?? ''} />
        </Header>

        {mobileMedia ? (
          <OurFeaturesSlider />
        ) : (
          <Cards>
            {ourFeaturesItems.map(({ image, description }, index: number) => (
              <OurValueCard
                image={image}
                description={description ?? ''}
                ourFeatures={true}
                key={index}
              />
            ))}
          </Cards>
        )}
      </ContentContainer>
    </Component>
  );
}

export default OurFeatures;

const Component = styled.section`
  background: white;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 300px;

  ${media.tablet(css`
    margin-top: 75px;
  `)}

  ${media.mobile(css`
    align-items: center;
  `)}
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 50px 0 70px 0;
  gap: 20px;

  ${media.tablet(css`
    grid-template-columns: repeat(2, 1fr);
    margin: 50px 0 60px 0;
  `)}

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;
