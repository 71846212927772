import React from 'react';
import styled from 'styled-components';

import Cover from '@/components/Cover';
import { useHomeData } from '@/modules/Home/Home.hooks';

import OurValues from './components/OurValues';
import Feedback from './components/Feedback';
import History from './components/History';
import Location from './components/Location';
import UnderCover from './components/UnderCover';

function Home() {
  const { title, image } = useHomeData();

  return (
    <Component>
      <Cover title={title ?? ''} image={image} />

      <UnderCover />

      <History />

      <Location />

      <OurValues />

      <Feedback />
    </Component>
  );
}

export default Home;

const Component = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
