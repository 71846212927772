import React from 'react';
import styled, { css } from 'styled-components';

import { useLogisticsData } from '@/modules/Logistics/Logistics.hooks';
import { media } from '@/utils/mixin';

import CoverCard from './CoverCard';

function CoverCards() {
  const { coverItems } = useLogisticsData();

  return (
    <Wrapper>
      <Component>
        <Cards>
          {coverItems.map(({ text }, index: number) => (
            <CoverCard text={text ?? ''} key={index} />
          ))}
        </Cards>
      </Component>
    </Wrapper>
  );
}

export default CoverCards;

const Wrapper = styled.div`
  padding: 0 20px;

  ${media.tablet(css`
    transform: translate(0, 99px);
    z-index: 2;
  `)}
`;

const Component = styled.div`
  position: relative;
  max-width: 1680px;
  width: 100%;
  margin: 66px auto;
  z-index: 2;
  background: white;
  padding: 82.5px 120px;

  ${media.tablet(css`
    margin: 0 auto;
    padding: 60px 80px;
  `)}

  ${media.mobile(css`
    padding: 20px 40px;
  `)}
`;

const Cards = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;
