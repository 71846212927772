import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useFormik } from 'formik';

import { ButtonWithArrow } from '@/components/Button';
import { useLogisticsData } from '@/modules/Logistics/Logistics.hooks';
import { submitFormContainer } from '@/services/requests';
import successImage from '@/assets/svg/success.svg';
import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';

import TextField from './components/TextField/TextField';
import { validationSchema } from './FindContainerForm.helpers';
import { FindContainerFormData } from './FindContainerForm.types';

function FindContainerForm() {
  const [isFormSend, setFormSend] = useState(false);

  const { findContainerFormRules, findContainerButtonTitle } =
    useLogisticsData();

  const { values, errors, touched, handleChange, handleSubmit, isSubmitting } =
    useFormik<FindContainerFormData>({
      initialValues: {
        container: '',
        email: '',
      },
      validationSchema,
      onSubmit: async (values, { setSubmitting }) => {
        setSubmitting(true);

        try {
          await submitFormContainer(values);
          setFormSend(true);
        } catch (error) {
          console.error(error);
        }

        setSubmitting(false);
      },
    });

  return isFormSend ? (
    <Message>
      <Image
        src={successImage}
        src2x={successImage}
        srcWebp={successImage}
        srcWebp2x={successImage}
      />
      <Content>
        <Title>Thank you!</Title>
        <Subtitle>We will contact you shortly</Subtitle>
      </Content>
    </Message>
  ) : (
    <Form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Container>
        <TextField
          placeholder="Sea container name"
          id="container"
          name="container"
          type="text"
          value={values.container}
          onChange={handleChange}
        />
        {errors.container && touched.container ? (
          <ErrorMessage>{findContainerFormRules}</ErrorMessage>
        ) : (
          <Rules>{findContainerFormRules}</Rules>
        )}
      </Container>

      <Container>
        <TextField
          placeholder="Your E-mail"
          id="email"
          name="email"
          type="email"
          value={values.email}
          onChange={handleChange}
        />
        {errors.email && touched.email ? (
          <ErrorMessage>{errors.email}</ErrorMessage>
        ) : (
          <EmailRules>Email rules</EmailRules>
        )}
      </Container>

      <Actions>
        <ButtonWithArrow
          title={findContainerButtonTitle ?? ''}
          findContainer={true}
          isLoading={isSubmitting}
        />
      </Actions>
    </Form>
  );
}

export default FindContainerForm;

const Form = styled.form`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 68px;
  margin-bottom: 85px;
  z-index: 1;

  ${media.tablet(css`
    flex-direction: column;
    height: 415px;
    margin-top: 32px;
    margin-bottom: 0;
  `)}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  &:nth-child(1) {
    margin-left: 0;
  }

  ${media.tablet(css`
    &:not(:first-child) {
      margin-left: 0;
      margin-top: 20px;
    }
  `)}
`;

const Rules = styled.span`
  margin-top: 11px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
`;

const EmailRules = styled(Rules)`
  color: transparent;
`;

const ErrorMessage = styled(Rules)`
  color: red !important;
`;

const Actions = styled.div`
  margin-left: 20px;

  ${media.tablet(css`
    margin-top: 9px;
    margin-left: 0;
  `)}
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  margin-top: 40px;

  ${media.tablet(css`
    flex-direction: column;
    margin-top: 15px;
  `)}

  ${media.mobile(css`
    margin-bottom: 20px;
  `)}
`;

const Image = styled(Picture)`
  position: relative;

  img {
    top: 0;
    left: 0;
    width: 130px;
    height: 130px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  ${media.tablet(css`
    margin-left: 0;
  `)}
`;

const Title = styled.p`
  color: white;
  font-size: 35px;
  line-height: 40px;
  text-align: center;
  font-weight: bold;

  ${media.laptop(
    css`
      font-size: 31px;
      line-height: 36px;
    `
  )}

  ${media.tablet(
    css`
      margin-top: 30px;
    `
  )}
`;

const Subtitle = styled.p`
  color: white;
  margin-top: 27px;
  font-size: 27px;
  line-height: 32px;
  text-align: center;

  ${media.laptop(
    css`
      font-size: 23px;
      line-height: 28px;
    `
  )}
`;
