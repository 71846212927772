import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { useHomeData } from '@/modules/Home/Home.hooks';
import { media } from '@/utils/mixin';

import HistoryCard from '../HistoryCard';

function History() {
  const { historyItems } = useHomeData();

  return (
    <Component>
      <Block />
      <ContentContainer>
        <Top>
          <HistoryCard
            text={historyItems[0].text ?? ''}
            backImage={historyItems[0].backImage}
            frontImage={historyItems[0].frontImage}
          />
        </Top>
        <Bottom>
          <HistoryCard
            text={historyItems[1].text ?? ''}
            backImage={historyItems[1].backImage}
            frontImage={historyItems[1].frontImage}
            direction="end"
          />
        </Bottom>
      </ContentContainer>
    </Component>
  );
}

export default History;

const Component = styled.section``;

const Block = styled.div`
  height: 210px;
  background: #e8eaed;
  position: relative;

  ${media.tablet(css`
    height: 200px;
  `)}
`;

const Top = styled.div`
  margin-top: -267px;

  ${media.tablet(css`
    margin-top: -100px;
  `)}
`;

const Bottom = styled.div`
  margin-top: 75px;

  ${media.mobile(css`
    margin-bottom: 75px;
  `)}
`;
