import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import SectionHeader from '@/components/SectionHeader';
import Picture from '@/components/Picture';
import { useHomeData } from '@/modules/Home/Home.hooks';
import { media } from '@/utils/mixin';
import { ContactFormData } from '@/modules/Home/components/ContactForm/ContactForm.types';
import { submitFormProject } from '@/services/requests';

import ContactForm from '../ContactForm';

function Feedback() {
  const { feedbackTitle, feedbackBackImage, feedbackFrontImage } =
    useHomeData();

  const saveFormFeedback = async (values: ContactFormData): Promise<void> => {
    await submitFormProject(values);
  };

  return (
    <Component>
      <ContentContainer>
        <Inner>
          <Left>
            <Header>
              <SectionHeader title={feedbackTitle ?? ''} />
            </Header>

            <Form>
              <ContactForm save={saveFormFeedback} />
            </Form>
          </Left>

          <Right>
            <BackImage
              src={feedbackBackImage.url}
              src2x={feedbackBackImage.url_2x}
              srcWebp={feedbackBackImage.url_webp}
              srcWebp2x={feedbackBackImage.url_webp_2x}
            />
            <FrontImage
              src={feedbackFrontImage.url}
              src2x={feedbackFrontImage.url_2x}
              srcWebp={feedbackFrontImage.url_webp}
              srcWebp2x={feedbackFrontImage.url_webp_2x}
            />
          </Right>
        </Inner>
      </ContentContainer>
    </Component>
  );
}

export default Feedback;

const Component = styled.section``;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    flex-direction: column-reverse;
    align-items: flex-end;
    margin-bottom: 100px;
  `)}

  ${media.mobile(css`
    align-items: flex-start;
    margin-bottom: 150px;
  `)}
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 682px;
  justify-content: flex-start;
`;

const Header = styled.div`
  margin-top: 265px;
  & > div > div {
    text-align: left;

    ${media.tablet(css`
      text-align: center;
    `)}

    ${media.mobile(css`
      text-align: left;
    `)}
  }

  ${media.tablet(css`
    margin-top: 50px;
  `)}
`;

const Right = styled.div`
  position: relative;
  flex: 1 1 562px;
  max-width: 562px;
  margin-left: 50px;

  ${media.tablet(css`
    flex: 1 1 420px;
    min-width: 420px;
    margin-left: 0;
  `)}

  ${media.mobile(css`
    flex: auto;
    min-width: 80%;
  `)}
`;

const BackImage = styled(Picture)`
  position: relative;
  padding-top: 160.144%;
  width: 100%;
  margin: 103px 0 322px 0;

  picture {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  ${media.tablet(css`
    padding-top: 121.905%;
    margin: 0;
  `)}
`;

const FrontImage = styled(Picture)`
  position: absolute !important;
  right: -12%;
  bottom: 12%;
  padding-top: 88.97%;
  width: 58.544%;

  picture {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  ${media.laptop2(
    css`
      right: -2%;
    `
  )}

  ${media.tablet(css`
    right: inherit;
    left: -40%;
    bottom: 12%;
    padding-top: 67.62%;
    width: calc(100% * 207 / 420);
  `)}
  
  ${media.mobile(css`
    left: inherit;
    right: -25%;
    bottom: -4%;
  `)}
`;

const Form = styled.div`
  margin-top: 80px;

  ${media.tablet(css`
    margin-top: 50px;
  `)}

  ${media.mobile(css`
    margin-top: 35px;
  `)}
`;
