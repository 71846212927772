import React from 'react';
import styled, { css } from 'styled-components';

import OurValueCard from '@/modules/Home/components/OurValueCard';
import { useSourcingActivityData } from '@/modules/SourcingActivity/SourcingActivity.hooks';
import { media } from '@/utils/mixin';

function SourcingCoverCards() {
  const { coverItems } = useSourcingActivityData();

  return (
    <Cards>
      {coverItems.map(({ image, description }, index: number) => (
        <OurValueCard
          image={image}
          description={description ?? ''}
          sourcingActivity={true}
          key={index}
        />
      ))}
    </Cards>
  );
}

export default SourcingCoverCards;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
  margin-top: 200px;
  position: relative;
  z-index: 2;

  ${media.tablet(css`
    grid-template-columns: repeat(1, 440px);
    gap: 40px;
    justify-content: center;
    transform: translate(0, 100px);
    margin-top: 25px;
  `)}

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
    transform: translate(0, 50px);
  `)}
`;
