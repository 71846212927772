import { useRouter } from 'next/router';

import { Nullish } from '@tager/web-core';
import { PageFullType } from '@tager/web-modules';

import { selectPageByPath } from '@/store/reducers/tager/pages';
import { useTypedSelector } from '@/store/store';
import { convertSlugToPath } from '@/utils/common';

function useCurrentPage<P extends PageFullType>(): P {
  const router = useRouter();

  const currentPath = convertSlugToPath(router.query.slug);

  const page = useTypedSelector((state) => selectPageByPath<P>(state, currentPath));

  if (!page) {
    throw new Error(`Page not found: ${currentPath}`);
  }

  return page;
}

export default useCurrentPage;
