import React from 'react';
import styled, { css } from 'styled-components';

import ContactForm from '@/modules/Home/components/ContactForm';
import Link from '@/components/Link';
import Picture from '@/components/Picture';
import { useContactsData } from '@/modules/Contacts/Contacts.hooks';
import { media } from '@/utils/mixin';
import { ContactFormData } from '@/modules/Home/components/ContactForm/ContactForm.types';
import { submitFormContact } from '@/services/requests';

function ConnectWithUs() {
  const { firstPhone, secondPhone, address, addressMark, email } =
    useContactsData();

  const phones = [firstPhone, secondPhone].filter(Boolean);

  const saveFormContact = async (values: ContactFormData): Promise<void> => {
    await submitFormContact(values);
  };

  return (
    <Component>
      <Left>
        <Address>
          <Text>{address}</Text>
          <Mark
            src={addressMark.url}
            src2x={addressMark.url_2x}
            srcWebp={addressMark.url_webp}
            srcWebp2x={addressMark.url_webp_2x}
          />
        </Address>
      </Left>

      <Right>
        <ContactInfo>
          {phones && (
            <List>
              {phones.map((phone, index) => (
                <Item key={index}>
                  <Phone to={`tel: ${phone}`}>{phone}</Phone>
                </Item>
              ))}
            </List>
          )}
          <Email to={`mailto: ${email}`}>{email}</Email>
        </ContactInfo>
        <ContactForm isContactsForm={true} save={saveFormContact} />
      </Right>
    </Component>
  );
}

export default ConnectWithUs;

const Component = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 50px 0 120px 0;

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;

const Left = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  max-width: 682px;
  min-height: 712px;
  flex: 0 0 682px;

  ${media.laptop(
    css`
      flex: 0 0 500px;
    `
  )}

  ${media.tablet(css`
    min-height: auto;
    flex: 0 0 400px;
  `)}
`;

const Address = styled.div`
  position: absolute;
  top: 160px;
  left: calc(100% * 150 / 682);
  max-width: 373px;

  ${media.mobile(css`
    top: 80px;
    left: inherit;
  `)}
`;

const Text = styled.p`
  position: relative;
  padding: 20px;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
  background: black;

  &:after {
    content: '';
    margin-left: -20px;
    border-color: black transparent transparent transparent;
    border-style: solid;
    border-width: 20px;
    width: 0;
    height: 0;
    position: absolute;
    bottom: -40px;
    left: 50%;
  }

  ${media.mobile(css`
    font-size: 16px;
    line-height: 24px;
  `)}
`;

const Mark = styled(Picture)`
  position: absolute !important;
  top: calc(100% * 20 / 94);
  left: calc(100% * 58 / 373);

  ${media.mobile(css`
    width: 100%;
    height: 100%;
    top: 155%;
    left: 50%;
    transform: translate(-50%, -50%);
  `)}
`;

const Right = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex: 0 0 682px;
  justify-content: flex-start;
  margin-left: 50px;

  ${media.tablet(css`
    margin-left: 0;
  `)}

  ${media.mobile(css`
    flex: auto;
  `)}
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

const List = styled.ul`
  display: flex;
`;

const Item = styled.li`
  &:not(:first-child) {
    margin-left: 25px;

    ${media.mobile(css`
      margin-left: 20px;
    `)}
  }
`;

const Phone = styled(Link)`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: white;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid white;
  }

  ${media.laptop(
    css`
      font-size: 17px;
      line-height: 27px;
    `
  )}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 27px;
  `)}
`;

const Email = styled(Phone)`
  margin-top: 10px;
  font-weight: normal;
`;
