import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import Picture from '@/components/Picture';
import CoverCards from '@/modules/Logistics/components/CoverCards';
import ConnectWithUs from '@/modules/Contacts/components/ConnectWithUs';
import SourcingCoverCards from '@/modules/SourcingActivity/components/SourcingCoverCards';
import { media } from '@/utils/mixin';

import { CoverProps } from './Cover.types';

function Cover({
  title,
  image,
  subtitle,
  coverCards,
  wholesale,
  secondTitle,
  sourcingActivity,
  contacts,
  logistics,
}: CoverProps) {
  return (
    <Component sourcingActivity={sourcingActivity} contacts={contacts}>
      <Background
        src={image.url}
        src2x={image.url_2x}
        srcWebp={image.url_webp}
        srcWebp2x={image.url_webp_2x}
        wholesale={wholesale}
        contacts={contacts}
        sourcingActivity={sourcingActivity}
      />
      <ContentContainer>
        <Title
          logistics={logistics}
          wholesale={wholesale}
          contacts={contacts}
          sourcingActivity={sourcingActivity}
        >
          {title}
        </Title>

        {secondTitle && (
          <>
            <br />
            <Title
              logistics={logistics}
              wholesale={wholesale}
              contacts={contacts}
              sourcingActivity={sourcingActivity}
            >
              {secondTitle}
            </Title>
          </>
        )}
        {subtitle && (
          <Subtitle>
            <Text>{subtitle}</Text>
          </Subtitle>
        )}
        {sourcingActivity && <SourcingCoverCards />}
        {contacts && <ConnectWithUs />}
      </ContentContainer>
      {coverCards && <CoverCards />}
    </Component>
  );
}

export default Cover;

const Component = styled.section<{
  sourcingActivity: CoverProps['sourcingActivity'];
  contacts: CoverProps['contacts'];
}>`
  position: relative;
  flex-direction: column;
  display: flex;
  margin-top: -80px;
  min-height: 1022px;

  ${media.tablet(css`
    min-height: 768px;
  `)}

  ${media.mobile(css`
    min-height: 320px;
  `)}

  ${({ sourcingActivity }) =>
    sourcingActivity &&
    css`
      margin-top: -150px;

      ${media.tablet(css`
        margin-top: -80px;
        background: black;
      `)}
    `};

  ${({ contacts }) =>
    contacts &&
    css`
      overflow: hidden;
    `};
`;

const Background = styled(Picture)<{
  wholesale: CoverProps['wholesale'];
  contacts: CoverProps['contacts'];
  sourcingActivity: CoverProps['sourcingActivity'];
}>`
  position: absolute !important;
  width: 100%;
  height: 100%;

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 94.53%);
  }

  ${({ wholesale }) =>
    wholesale &&
    css`
      &:after {
        background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.4) 94.53%);
      }
    `};

  ${({ contacts }) =>
    contacts &&
    css`
      height: 100%;
      
      &:after {
        background: radial-gradient(70.35% 60.47% at 15.12% 70.53%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 70.76%);
        );
      }
    `};

  ${({ sourcingActivity }) =>
    sourcingActivity &&
    css`
      height: 1150px;
    `};
`;

const Title = styled.p<{
  logistics: CoverProps['logistics'];
  wholesale: CoverProps['wholesale'];
  contacts: CoverProps['contacts'];
  sourcingActivity: CoverProps['sourcingActivity'];
}>`
  position: relative;
  margin: 289px auto 0 auto;
  font-size: 75px;
  line-height: 94px;
  text-align: center;
  color: #fff;
  z-index: 2;

  ${media.laptop(css`
    font-size: 60px;
    line-height: 74px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 60px;
    margin-top: 189px;
    max-width: 1000px;
  `)}
  
  ${media.mobile(css`
    font-size: 32px;
    line-height: 40px;
    max-width: 500px;
    text-align: left;
    margin: 139px 0 100px 0;
  `)}
  
  ${({ logistics }) =>
    logistics &&
    css`
      ${media.mobile(css`
        margin-bottom: 0;
      `)}
    `};

  ${({ wholesale }) =>
    wholesale &&
    css`
      margin-top: 0;
      font-size: 55px;
      line-height: 82px;

      &:first-child {
        margin-top: 220px;

        ${media.tablet(css`
          margin-top: 189px;
        `)}

        ${media.mobile(css`
          margin-bottom: 0;
        `)}
      }

      ${media.laptop(css`
        font-size: 50px;
        line-height: 77px;
      `)}

      ${media.tablet(css`
        margin-top: 0;
        font-size: 36px;
        line-height: 54px;
      `)}
      
      ${media.mobile(css`
        font-size: 32px;
        line-height: 40px;
      `)}
    `};

  ${({ contacts }) =>
    contacts &&
    css`
      margin-top: 189px;

      ${media.mobile(css`
        margin-bottom: 0;
      `)}
    `};

  ${({ sourcingActivity }) =>
    sourcingActivity &&
    css`
      margin-top: 350px;

      ${media.mobile(css`
        margin-bottom: 0;
        text-shadow: 0 3.77811px 3.77811px rgba(0, 0, 0, 0.25);
      `)}
    `};
`;

const Subtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.mobile(css`
    justify-content: flex-start;
    align-items: flex-start;
  `)}
`;

const Text = styled.p`
  position: relative;
  color: #fff;
  margin-top: 24px;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  z-index: 2;
  max-width: 1169px;

  ${media.laptop(css`
    margin-top: 19px;
    font-size: 25px;
  `)}

  ${media.tablet(css`
    margin-top: 25px;
    font-size: 30px;
    line-height: 45px;
  `)}
  
  ${media.mobile(css`
    text-align: left;
    font-size: 18px;
    line-height: 22px;
    max-width: 500px;
  `)}
`;
