import React from 'react';
import styled, { css } from 'styled-components';

import { CoverProps } from '@/components/Cover/Cover.types';
import { media } from '@/utils/mixin';

import SectionHeaderProps from './SectionHeader.types';

function SectionHeader({ title, wholesale }: SectionHeaderProps) {
  return (
    <Header wholesale={wholesale}>
      <Title dangerouslySetInnerHTML={{ __html: title }} />
    </Header>
  );
}

export default SectionHeader;

const Header = styled.div<{ wholesale: CoverProps['wholesale'] }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ wholesale }) =>
    wholesale &&
    css`
      padding: 55px 0;
      background: linear-gradient(180deg, #ffffff 47.64%, #f7f7f7 100%);
      margin-top: -65px;
      z-index: 1;

      ${media.tablet(css`
        margin-top: -50px;
      `)}

      ${media.mobile(css`
        margin-top: -40px;
        padding: 16px 0;
      `)}
    `};
`;

const Title = styled.div`
  text-align: center;
  font-size: 48px;
  line-height: 72px;

  ${media.laptop(css`
    font-size: 44px;
    line-height: 66px;
    text-align: left;
  `)}

  ${media.mobile(css`
    font-size: 32px;
    line-height: 48px;
  `)}
`;
