import React from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import Link from '@/components/Link';
import { CoverProps } from '@/components/Cover/Cover.types';
import { media } from '@/utils/mixin';

import arrowBlack from '../../../../assets/svg/arrow-right.svg';
import arrowWhite from '../../../../assets/svg/arrow-right-white.svg';

import { OurCompanyCardProps } from './OurCompanyCard.types';

function OurCompanyCard({
  image,
  description,
  to,
  wholesale,
}: OurCompanyCardProps) {
  const redirect = () => (window.location.href = `/${to}`);

  return (
    <Card wholesale={wholesale} onClick={redirect}>
      <CardImage
        src={image.url}
        src2x={image.url_2x}
        srcWebp={image.url_webp}
        srcWebp2x={image.url_webp_2x}
        wholesale={wholesale}
      />
      <CardContent wholesale={wholesale}>
        <CardDescription wholesale={wholesale}>{description}</CardDescription>
        {!wholesale && (
          <Actions>
            <MoreDetails to={to}>
              <Icon />
            </MoreDetails>
          </Actions>
        )}
      </CardContent>
    </Card>
  );
}

export default OurCompanyCard;

const Card = styled.div<{ wholesale: CoverProps['wholesale'] }>`
  position: relative;
  background: #333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  z-index: 2;
  cursor: pointer;

  &:nth-child(1) {
    transform: translate(0, -307px);
  }

  &:nth-child(2) {
    transform: translate(0, -217px);
  }

  &:nth-child(3) {
    transform: translate(0, -127px);
  }

  ${media.tablet(css`
    grid-column-start: 2;

    &:nth-child(n) {
      transform: translate(0, -240px);
    }
  `)}

  ${media.mobile(css`
    &:nth-child(n) {
      transform: translate(0, 50px);
    }
  `)}

  ${({ wholesale }) =>
    wholesale &&
    css`
      pointer-events: none;

      &:nth-child(n) {
        transform: translate(0, -120px);
      }

      ${media.tablet(css`
        grid-column-start: auto;

        &:nth-child(n) {
          transform: translate(0, 0);
        }
      `)}
    `};
`;

const CardImage = styled(Picture)<{ wholesale: CoverProps['wholesale'] }>`
  position: relative;
  padding-top: 73.525%;
  background: rgb(48 48 48 / 30%);

  picture {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  ${({ wholesale }) =>
    wholesale &&
    css`
      padding-top: 95.589%;

      ${media.tablet(css`
        padding-top: calc(100% * 220 / 320);
      `)}
    `};
`;

const CardContent = styled.div<{ wholesale: CoverProps['wholesale'] }>`
  padding: 32px 32px 43px 37px;

  ${({ wholesale }) =>
    wholesale &&
    css`
      padding: 30px 20px;
    `};

  ${media.mobile(css`
    padding: 25px 20px;
  `)}
`;

const CardDescription = styled.p<{ wholesale: CoverProps['wholesale'] }>`
  height: 189px;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;

  ${media.mobile(css`
    font-size: 16px;
    line-height: 24px;
  `)}

  ${({ wholesale }) =>
    wholesale &&
    css`
      font-size: 17px;
      line-height: 25px;
      height: auto;
      overflow: inherit;
      text-overflow: inherit;
      display: inherit;
      -webkit-line-clamp: inherit;
      -webkit-box-orient: inherit;

      ${media.tablet(css`
        font-size: 16px;
        line-height: 24px;
        min-height: 100px;
      `)}
    `};
`;

const Actions = styled.div`
  display: flex;
  position: absolute;
  background: white;
  align-items: center;
  justify-content: center;
  bottom: 0;
  right: 0;
`;

const Icon = styled.div`
  background-image: url(${arrowBlack});
  width: 41px;
  height: 15px;
`;

const MoreDetails = styled(Link)`
  padding: 32.5px 19.5px;

  &:hover {
    background: #ff0000;

    div${Icon} {
      background-image: url(${arrowWhite});
    }
  }
`;
