import React from 'react';
import styled from 'styled-components';

import Cover from '@/components/Cover';
import UnderCover from '@/modules/Wholesale/components/UnderCover';
import ExchangeTraded from '@/modules/Wholesale/components/ExchangeTraded';
import OurAdvantages from '@/modules/Wholesale/components/OurAdvantages';
import BottomCards from '@/modules/Wholesale/components/BottomCards';
import { useWholesaleData } from '@/modules/Wholesale/Wholesale.hooks';

function Wholesale() {
  const { firstTitle, secondTitle, image } = useWholesaleData();

  return (
    <Component>
      <Cover
        title={firstTitle ?? ''}
        secondTitle={secondTitle ?? ''}
        image={image}
        wholesale={true}
      />
      <UnderCover />
      <ExchangeTraded />
      <OurAdvantages />
      <BottomCards />
    </Component>
  );
}

export default Wholesale;

const Component = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
