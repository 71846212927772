import React from 'react';
import styled from 'styled-components';

import Cover from '@/components/Cover';
import { useContactsData } from '@/modules/Contacts/Contacts.hooks';

function Contacts() {
  const { title, subtitle, image } = useContactsData();

  return (
    <Component>
      <Cover
        title={title ?? ''}
        subtitle={subtitle ?? ''}
        image={image}
        contacts={true}
      />
    </Component>
  );
}

export default Contacts;

const Component = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
