import { Nullish } from '@tager/web-core';

import { PageModuleType } from '@/typings/common';
import DefaultTemplate from '@/modules/DefaultTemplate';
import Home from '@/modules/Home';
import Logistics from '@/modules/Logistics';
import Wholesale from '@/modules/Wholesale';
import Contacts from '@/modules/Contacts';
import SourcingActivity from '@/modules/SourcingActivity';

const DEFAULT_PAGE_MODULE: PageModuleType = {
  component: DefaultTemplate,
  template: '',
};

const PAGE_MODULE_LIST: Array<PageModuleType> = [
  {
    template: 'home',
    component: Home,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'logistics',
    component: Logistics,
    getInitialProps() {
      return Promise.all([]);
    },
  },

  {
    template: 'wholesale',
    component: Wholesale,
    getInitialProps() {
      return Promise.all([]);
    },
  },

  {
    template: 'sourcing-activity',
    component: SourcingActivity,
    getInitialProps() {
      return Promise.all([]);
    },
  },

  {
    template: 'contacts',
    component: Contacts,
    getInitialProps() {
      return Promise.all([]);
    },
  },
];

export function getPageModuleByTemplate(
  template: Nullish<string>
): PageModuleType {
  return (
    PAGE_MODULE_LIST.find((pageModule) => pageModule.template === template) ??
    DEFAULT_PAGE_MODULE
  );
}
