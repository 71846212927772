import { request } from '@tager/web-core';

export function getExampleList() {
  return request.get({ path: '/tager/example' });
}

export function createExample(params: {
  name: string;
  email: string;
  message: string;
}) {
  return request.post({ path: '/tager/example', body: params });
}

export function submitFormProject(params: {
  name: string;
  email: string;
  message: string;
}) {
  return request.post({ path: '/leads/project', body: params });
}

export function submitFormContainer(params: {
  container: string;
  email: string;
}) {
  return request.post({ path: '/leads/container', body: params });
}

export function submitFormContact(params: {
  name: string;
  email: string;
  message: string;
}) {
  return request.post({ path: '/leads/contact', body: params });
}
