import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import SectionHeader from '@/components/SectionHeader';
import OurValueCard from '@/modules/Home/components/OurValueCard';
import { useWholesaleData } from '@/modules/Wholesale/Wholesale.hooks';
import { media } from '@/utils/mixin';

function OurAdvantages() {
  const { ourAdvantagesTitle, ourAdvantages } = useWholesaleData();

  return (
    <Component>
      <ContentContainer>
        <SectionHeader title={ourAdvantagesTitle ?? ''} wholesale={true} />

        <Cards>
          {ourAdvantages.map(({ image, description }, index: number) => (
            <OurValueCard
              image={image}
              description={description ?? ''}
              ourAdvantages={true}
              key={index}
            />
          ))}
        </Cards>
      </ContentContainer>
    </Component>
  );
}

export default OurAdvantages;

const Component = styled.section`
  background: white;
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin: 40px 0 215px 0;

  ${media.tablet(css`
    position: relative;
    justify-content: center;
    grid-template-columns: repeat(1, 440px);
    margin: 50px 0 150px 0;
  `)}

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;
