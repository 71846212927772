import React from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import SectionHeader from '@/components/SectionHeader';
import { FindContainerProps } from '@/modules/Logistics/components/FindContainer/FindContainer.types';
import { ButtonWithArrow } from '@/components/Button';
import { media } from '@/utils/mixin';

import FindContainerForm from '../FindContainerForm';

function FindContainer({
  image,
  title,
  sourcingActivity,
  subtitle,
  buttonTitle,
}: FindContainerProps) {
  return (
    <Component>
      <Block sourcingActivity={sourcingActivity}>
        <Find>
          <Background
            sourcingActivity={sourcingActivity}
            src={image.url}
            src2x={image.url_2x}
            srcWebp={image.url_webp}
            srcWebp2x={image.url_webp_2x}
          />
          <Header sourcingActivity={sourcingActivity}>
            <SectionHeader title={title} />
          </Header>
          {sourcingActivity ? (
            <>
              <Subtitle>{subtitle}</Subtitle>
              <Action>
                <ButtonWithArrow
                  title={buttonTitle ?? ''}
                  easyStart={true}
                  buttonLinkMode={true}
                  to="/contacts"
                />
              </Action>
            </>
          ) : (
            <FindContainerForm />
          )}
        </Find>
      </Block>
    </Component>
  );
}

export default FindContainer;

const Component = styled.section`
  position: relative;
`;

const Block = styled.div<{
  sourcingActivity: FindContainerProps['sourcingActivity'];
}>`
  position: relative;
  height: 300px;
  background: black;
  padding: 0 20px;

  ${media.tablet(css`
    height: 415px;
  `)}

  ${({ sourcingActivity }) =>
    sourcingActivity &&
    css`
      ${media.mobile(css`
        max-height: 270px;
      `)}
    `};
`;

const Find = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
  bottom: 200px;
  max-width: 1680px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  ${media.tablet(css`
    bottom: 138px;
  `)}
`;

const Background = styled(Picture)<{
  sourcingActivity: FindContainerProps['sourcingActivity'];
}>`
  position: absolute !important;
  height: 100%;
  width: 100%;

  picture {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: radial-gradient(
        50% 210% at 0% 100%,
        #333333 0%,
        rgba(51, 51, 51, 0.33) 66.25%
      ),
      linear-gradient(
        180deg,
        rgba(51, 51, 51, 0.9) 0%,
        rgba(51, 51, 51, 0) 50.65%
      );
  }

  ${({ sourcingActivity }) =>
    sourcingActivity &&
    css`
      &:after {
        background: radial-gradient(
            50% 210% at 0% 100%,
            #333333 0%,
            rgba(51, 51, 51, 0.33) 66.25%
          ),
          linear-gradient(
            180deg,
            rgba(51, 51, 51, 0.225) 0%,
            rgba(51, 51, 51, 0) 50.65%
          );
      }
    `};
`;

const Header = styled.div<{
  sourcingActivity: FindContainerProps['sourcingActivity'];
}>`
  margin-top: 60px;
  position: relative;
  color: white;
  z-index: 1;

  ${media.tablet(css`
    margin-top: 30px;
  `)}

  ${({ sourcingActivity }) =>
    sourcingActivity &&
    css`
      ${media.mobile(css`
        margin-top: 70px;
      `)}
    `};
`;

const Subtitle = styled.p`
  position: relative;
  text-align: center;
  max-width: 709px;
  margin-top: 24px;
  font-size: 30px;
  line-height: 45px;
  color: white;
  text-shadow: 0 3.77811px 3.77811px rgba(0, 0, 0, 0.25);
  z-index: 1;

  ${media.laptop(css`
    font-size: 27px;
    line-height: 42px;
  `)}

  ${media.mobile(css`
    font-size: 18px;
    line-height: 27px;
    max-width: 500px;
  `)}
`;

const Action = styled.div`
  position: relative;
  z-index: 1;
  margin-top: 30px;
`;
