import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import SectionHeader from '@/components/SectionHeader';
import HistoryCard from '@/modules/Home/components/HistoryCard';
import { useLogisticsData } from '@/modules/Logistics/Logistics.hooks';
import { media } from '@/utils/mixin';

function EffectiveServices() {
  const { effectiveTitle, effectiveItems } = useLogisticsData();

  return (
    <Component>
      <ContentContainer>
        <Header>
          <SectionHeader title={effectiveTitle ?? ''} />
        </Header>

        <Cards>
          {effectiveItems.map(
            ({ title, description, backImage, frontImage }, index: number) => (
              <HistoryCard
                title={title ?? ''}
                text={description ?? ''}
                backImage={backImage}
                frontImage={frontImage}
                direction={(index + 1) % 2 === 0 ? 'end' : 'start'}
                effectiveServices={true}
                key={index}
              />
            )
          )}
        </Cards>
      </ContentContainer>
    </Component>
  );
}

export default EffectiveServices;

const Component = styled.section`
  background: #f8f8f8;
`;

const Header = styled.div`
  margin-top: 100px;

  ${media.tablet(css`
    margin-top: 175px;

    & > div > div {
      text-align: center;
    }
  `)}
`;

const Cards = styled.div`
  margin: 81px 0 150px 0;

  ${media.tablet(css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 124px 0 100px 0;
  `)}

  ${media.mobile(css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
  `)}
`;
