import React from 'react';
import styled from 'styled-components';

import Cover from '@/components/Cover';
import BuyingAgent from '@/modules/SourcingActivity/components/BuyingAgent';
import OurFeatures from '@/modules/SourcingActivity/components/OurFeatures';
import OurSupport from '@/modules/SourcingActivity/components/OurSupport';
import FindContainer from '@/modules/Logistics/components/FindContainer';
import EasySteps from '@/modules/SourcingActivity/components/EasySteps';
import { useSourcingActivityData } from '@/modules/SourcingActivity/SourcingActivity.hooks';

function SourcingActivity() {
  const {
    title,
    subtitle,
    image,
    easyStartImage,
    easyStartTitle,
    easyStartSubtitle,
    easyStartButtonTitle,
  } = useSourcingActivityData();

  return (
    <Component>
      <Cover
        title={title ?? ''}
        subtitle={subtitle ?? ''}
        image={image ?? ''}
        sourcingActivity={true}
      />
      <BuyingAgent />
      <OurFeatures />
      <EasySteps />
      <OurSupport />
      <FindContainer
        image={easyStartImage ?? ''}
        title={easyStartTitle ?? ''}
        subtitle={easyStartSubtitle ?? ''}
        buttonTitle={easyStartButtonTitle ?? ''}
        sourcingActivity={true}
      />
    </Component>
  );
}

export default SourcingActivity;

const Component = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
