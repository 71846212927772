import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import SectionHeader from '@/components/SectionHeader';
import OurValueCard from '@/modules/Home/components/OurValueCard';
import { useLogisticsData } from '@/modules/Logistics/Logistics.hooks';
import { media } from '@/utils/mixin';

function FollowingServices() {
  const { followingTitle, followingItems } = useLogisticsData();

  return (
    <Component>
      <ContentContainer>
        <Header>
          <SectionHeader title={followingTitle ?? ''} />
        </Header>

        <Cards>
          {followingItems.map(({ image, text }, index: number) => (
            <OurValueCard
              image={image}
              description={text ?? ''}
              followingServices={true}
              key={index}
            />
          ))}
        </Cards>
      </ContentContainer>
    </Component>
  );
}

export default FollowingServices;

const Component = styled.section`
  background: #ffffff;
`;

const Header = styled.div`
  margin-top: 150px;

  ${media.tablet(css`
    margin-top: 75px;
    display: flex;
    align-items: flex-start;
  `)}

  ${media.mobile(css`
    margin-top: 50px;
  `)}
`;

const Cards = styled.div`
  display: grid;
  grid-template: repeat(2, 1fr) / repeat(6, 1fr);
  gap: 45px;
  margin: 75px 0 335px 0;

  & > div :nth-child(1) {
    grid-area: 1 / 1 / 2 / 3;
  }
  & > div:nth-child(2) {
    grid-area: 1 / 3 / 2 / 5;
  }
  & > div:nth-child(3) {
    grid-area: 1 / 5 / 2 / 7;
  }
  & > div:nth-child(4) {
    grid-area: 2 / 2 / 3 / 4;
  }
  & > div:nth-child(5) {
    grid-area: 2 / 4 / 3 / 6;
  }

  ${media.laptop(css`
    gap: 30px;
  `)}

  ${media.tablet(css`
    & > div :nth-child(n) {
      grid-area: inherit;
    }
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin: 50px 0 208px 0;
  `)}
  
  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;
