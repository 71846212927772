import React from 'react';
import styled from 'styled-components';

import { TextAreaProps } from './TextArea.types';

function TextArea({ errorMessage, ...props }: TextAreaProps) {
  return (
    <Component>
      <Area {...props} />
      {errorMessage && <Error>{errorMessage}</Error>}
    </Component>
  );
}

export default TextArea;

const Component = styled.div`
  position: relative;
  margin-top: 19px;
`;

const Area = styled.textarea`
  display: block;
  width: 100%;
  height: 162px;
  padding: 21px 35px;
  font-size: 18px;
  line-height: 27px;
  border: none;
  resize: none;
  background: #e8eaed;

  &::placeholder {
    color: black;
  }
`;

const Error = styled.div`
  position: absolute;
  color: red;
  font-size: 15px;
  line-height: 18px;
`;
