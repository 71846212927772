import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import BuyingAgentCard from '@/modules/SourcingActivity/components/BuyingAgent/Card';
import SectionHeader from '@/components/SectionHeader';
import { ButtonWithArrow } from '@/components/Button';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { useSourcingActivityData } from '@/modules/SourcingActivity/SourcingActivity.hooks';

function BuyingAgent() {
  const {
    startWithAgentBackground,
    buyingAgentTitle,
    buyingAgentItems,
    startWithAgentTitle,
    startWithAgentButtonTitle,
    startWithAgentImage,
  } = useSourcingActivityData();

  return (
    <Component>
      <Background
        src={startWithAgentBackground.url}
        src2x={startWithAgentBackground.url_2x}
        srcWebp={startWithAgentBackground.url_webp}
        srcWebp2x={startWithAgentBackground.url_webp_2x}
      />
      <ContentContainer>
        <Header>
          <SectionHeader title={buyingAgentTitle ?? ''} />
        </Header>
        <Cards>
          {buyingAgentItems.map(({ text }, index: number) => (
            <BuyingAgentCard text={text ?? ''} key={index} />
          ))}
        </Cards>
        <SourcingAgent>
          <Left>
            <Heading
              dangerouslySetInnerHTML={{
                __html: startWithAgentTitle ?? '',
              }}
            />
            <Action>
              <ButtonWithArrow
                title={startWithAgentButtonTitle ?? ''}
                sourcingAgent={true}
                buttonLinkMode={true}
                to="/contacts"
              />
            </Action>
          </Left>

          <Right>
            <Image
              src={startWithAgentImage.url}
              src2x={startWithAgentImage.url_2x}
              srcWebp={startWithAgentImage.url_webp}
              srcWebp2x={startWithAgentImage.url_webp_2x}
            />
          </Right>
        </SourcingAgent>
      </ContentContainer>
    </Component>
  );
}

export default BuyingAgent;

const Component = styled.section`
  z-index: 1;
  position: relative;
  background: linear-gradient(180deg, #f8f8f8 0%, #ffffff 56.56%);

  ${media.tablet(css`
    background: linear-gradient(180deg, #ffffff 78.39%, #f8f8f8 100%), #ffffff;
  `)}
`;

const Header = styled.div`
  margin-top: 150px;

  ${media.tablet(css`
    margin-top: 200px;

    & > div > div {
      text-align: center;
    }
  `)}

  ${media.mobile(css`
    margin-top: 115px;
  `)}
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  ${media.tablet(css`
    margin-top: 25px;
  `)}
`;

const SourcingAgent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  ${media.tablet(css`
    flex-direction: column-reverse;
    justify-content: inherit;
    align-items: center;
    margin-bottom: 100px;
  `)}
`;

const Background = styled(Picture)`
  position: absolute !important;
  bottom: 0;
  width: 31.38%;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 713px;
  justify-content: flex-start;

  ${media.laptop(css`
    flex: auto;
    margin-top: 150px;
    align-items: center;
  `)}
`;

const Heading = styled.div`
  font-size: 40px;
  line-height: 60px;

  ${media.laptop(css`
    font-size: 35px;
    line-height: 55px;
    text-align: center;
  `)}

  ${media.mobile(css`
    font-size: 32px;
    line-height: 48px;
  `)}
`;

const Action = styled.div`
  display: flex;
  margin-top: 70px;

  ${media.laptop(css`
    margin-top: 50px;
  `)}
`;

const Right = styled.div`
  position: relative;
  flex: 1 1 527px;
  max-width: 527px;
  margin: 0 100px 0 50px;

  ${media.tablet(css`
    flex: auto;
    width: 100%;
    margin: 0;
  `)}
`;

const Image = styled(Picture)`
  position: relative;
  padding-top: 134.727%;
  width: 100%;
  margin: 100px 0 -50px 0;

  picture {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &:before {
    content: '';
    width: 100%;
    height: calc(100% * 610 / 710);
    position: absolute;
    background: #f0f0f0;
    right: calc(100% * (-100) / 527);
    top: calc(100% * 200 / 710);
  }

  ${media.tablet(css`
    padding-top: 112.62%;
    margin: 95px 0 0 0;

    &:before {
      right: calc(100% * (-90) / 420);
      top: calc(100% * 90 / 473);
      height: 100%;
    }
  `)}

  ${media.mobile(css`
    &:before {
      right: calc(100% * (-20) / 420);
    }
  `)}
`;
