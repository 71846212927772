import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import OurCompanyCard from '@/modules/Home/components/OurCompanyCard';
import { useHomeData } from '@/modules/Home/Home.hooks';
import { media } from '@/utils/mixin';

function UnderCover() {
  const { underCoverItems } = useHomeData();

  return (
    <Component>
      <ContentContainer>
        <Cards>
          {underCoverItems.map(({ image, description, to }, index: number) => (
            <OurCompanyCard
              image={image}
              description={description ?? ''}
              to={to ?? ''}
              key={index}
            />
          ))}
        </Cards>
      </ContentContainer>
    </Component>
  );
}

export default UnderCover;

const Component = styled.section`
  background: black;
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 35px;

  ${media.tablet(css`
    grid-template-columns: 1fr 420px 1fr;
    gap: 40px 0;
  `)}

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;
