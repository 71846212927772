import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { BuyingAgentCardType } from './BuyingAgentCard.types';

function BuyingAgentCard({ text }: BuyingAgentCardType) {
  return (
    <Card>
      <Text dangerouslySetInnerHTML={{ __html: text }} />
    </Card>
  );
}

export default BuyingAgentCard;

const Card = styled.div`
  background: white;
  padding: 40px;
  box-shadow: inset 4px 0 0 #000000, inset 0px -2px 0px rgba(0, 0, 0, 0.25);
  border-left: 2px solid black;
  transition: 0.3s;
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 20px;
  }

  &:hover {
    border-left: 15px solid black;
    transition: 0.3s;
  }

  ${media.tablet(css`
    padding: 20px;

    &:hover {
      border-left: 2px solid black;
      transition: 0.3s;
    }
  `)}
`;

const Text = styled.div`
  font-size: 28px;
  line-height: 42px;

  ${media.laptop(css`
    font-size: 25px;
    line-height: 39px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;
  `)}
  
   ${media.mobile(css`
    font-size: 18px;
    line-height: 27px;
  `)}
`;
