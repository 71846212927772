import React from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

import { HistoryCardProps } from './HistoryCard.types';

function HistoryCard({
  text,
  backImage,
  frontImage,
  direction = 'start',
  title,
  effectiveServices,
  wholesale,
  ourSupport,
}: HistoryCardProps) {
  return (
    <Component
      direction={direction}
      effectiveServices={effectiveServices}
      wholesale={wholesale}
      ourSupport={ourSupport}
    >
      <Image
        direction={direction}
        effectiveServices={effectiveServices}
        wholesale={wholesale}
        ourSupport={ourSupport}
      >
        <BackImage
          direction={direction}
          effectiveServices={effectiveServices}
          wholesale={wholesale}
          ourSupport={ourSupport}
          src={backImage.url}
          src2x={backImage.url_2x}
          srcWebp={backImage.url_webp}
          srcWebp2x={backImage.url_webp_2x}
        />
        {frontImage && (
          <FrontImage
            direction={direction}
            effectiveServices={effectiveServices}
            wholesale={wholesale}
            ourSupport={ourSupport}
            src={frontImage?.url}
            src2x={frontImage?.url_2x}
            srcWebp={frontImage?.url_webp}
            srcWebp2x={frontImage?.url_webp_2x}
          />
        )}
      </Image>

      <Content
        direction={direction}
        effectiveServices={effectiveServices}
        wholesale={wholesale}
        ourSupport={ourSupport}
      >
        {title && (
          <Title
            effectiveServices={effectiveServices}
            wholesale={wholesale}
            ourSupport={ourSupport}
          >
            {title}
          </Title>
        )}
        <Text
          direction={direction}
          effectiveServices={effectiveServices}
          wholesale={wholesale}
          ourSupport={ourSupport}
        >
          {text}
        </Text>
      </Content>
    </Component>
  );
}

export default HistoryCard;

const Component = styled.div<{
  direction: HistoryCardProps['direction'];
  effectiveServices: HistoryCardProps['effectiveServices'];
  wholesale: HistoryCardProps['wholesale'];
  ourSupport: HistoryCardProps['ourSupport'];
}>`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${media.tablet(css`
    justify-content: space-around;
  `)}

  ${media.mobile(css`
    flex-direction: column;
    align-items: flex-start;
  `)}

  ${({ direction }) =>
    direction === 'end' &&
    css`
      flex-direction: row-reverse;
      align-items: flex-start;
    `};

  ${({ effectiveServices }) =>
    effectiveServices &&
    css`
      align-items: stretch;
      justify-content: center;

      ${media.tablet(css`
        flex-direction: column;
      `)}

      ${media.mobile(css`
        align-items: initial;
      `)}

      &:not(:first-child) {
        margin-top: 75px;

        ${media.tablet(css`
          margin-top: 40px;
        `)}
      }

      &:nth-child(odd) > div:nth-child(2) {
        padding: 0 60px 0 80px;

        ${media.tablet(css`
          padding: 0 40px;
        `)}

        ${media.mobile(css`
          padding: 20px;
        `)}
      }

      &:nth-child(even) > div:nth-child(2) {
        padding: 0 80px 0 60px;

        ${media.tablet(css`
          padding: 0 40px;
        `)}

        ${media.mobile(css`
          padding: 20px;
        `)}
      }

      &:nth-child(2) > div:nth-child(2) > p:nth-child(1) {
        &:before {
          background: #ff0000;
        }
      }

      &:nth-child(3) > div:nth-child(2) > p:nth-child(1) {
        &:before {
          background: black;
        }
      }

      &:nth-child(2) > div:nth-child(1) > div:nth-child(2) {
        width: calc(100% * 245 / 720);
        left: calc(100% * 570 / 720);
        top: calc(100% * 132 / 720);

        ${media.laptop2(css`
          left: inherit;
          top: inherit;
          right: -2%;
        `)}

        ${media.tablet(css`
          bottom: inherit;
          top: calc(100% * 76 / 640);
          right: calc(100% * (-20) / 640);
          width: calc(100% * 259 / 640);
          padding-top: calc(100% * 366 / 640);
        `)}
      }

      &:nth-child(3) > div:nth-child(1) > div:nth-child(2) {
        width: calc(100% * 245 / 720);
        top: calc(100% * 344 / 600);
        left: -8.33%;

        ${media.laptop2(css`
          left: -2%;
          top: -5%;
        `)}

        ${media.tablet(css`
          top: calc(100% * 84 / 640);
          left: calc(100% * (-20) / 640);
          width: calc(100% * 259 / 640);
          padding-top: calc(100% * 366 / 640);
        `)}
      }
    `};

  ${({ wholesale, ourSupport }) =>
    (wholesale || ourSupport) &&
    css`
      margin-top: 0;
      align-items: stretch;
      justify-content: center;

      ${media.tablet(css`
        flex-direction: column;
      `)}

      ${media.mobile(css`
        align-items: initial;
      `)}

      &:nth-child(even) > div:nth-child(2) {
        padding: 0 80px 0 60px;

        ${media.tablet(css`
          padding: 0 40px;
        `)}

        ${media.mobile(css`
          padding: 20px;
        `)}
      }

      &:nth-child(odd) > div:nth-child(2) {
        padding: 0 60px 0 80px;

        ${media.tablet(css`
          padding: 0 40px;
        `)}

        ${media.mobile(css`
          padding: 20px;
        `)}
      }

      &:not(:first-child) {
        margin-top: 75px;

        ${media.tablet(css`
          margin-top: 40px;
        `)}
      }
    `};

  ${({ ourSupport }) =>
    ourSupport &&
    css`
      &:nth-child(2) > div:nth-child(1) > div {
        padding-top: 111.112%;
      }

      &:nth-child(1) > div:nth-child(1) > div:nth-child(2) {
        ${media.tablet(css`
          transform: rotate(180deg);
        `)}
      }

      &:nth-child(2) > div:nth-child(1) > div:nth-child(2) {
        width: calc(100% * 336 / 720);
        padding-top: 46.668%;
        left: calc(100% * (-141) / 720);
        top: calc(100% * 132 / 800);

        ${media.laptop2(css`
          left: calc(100% * (-20) / 720);
          top: calc(100% * (-20) / 800);
        `)}

        ${media.tablet(css`
          padding-top: calc(100% * 259 / 640);
          width: calc(100% * 259 / 640);
          left: inherit;
          right: calc(100% * (-20) / 640);
          top: calc(100% * (76) / 800);
        `)}
      }

      &:nth-child(3) > div:nth-child(1) > div:nth-child(2) {
        width: calc(100% * 316 / 720);
        padding-top: 51.807%;
        top: inherit;
        bottom: calc(100% * (-40) / 600);
        right: calc(100% * (-158) / 720);

        ${media.laptop2(css`
          bottom: calc(100% * (-40) / 600);
          right: calc(100% * (-20) / 720);
        `)}

        ${media.tablet(css`
          padding-top: calc(100% * 336 / 640);
          width: calc(100% * 259 / 640);
          left: calc(100% * (-20) / 640);
          bottom: calc(100% * (83) / 533.3);
        `)}
      }

      &:nth-child(4) > div:nth-child(1) > div:nth-child(2) {
        width: calc(100% * 336 / 720);
        padding-top: 72.5%;
        left: calc(100% * (-141) / 720);
        top: calc(100% * 49 / 600);

        ${media.laptop2(css`
          left: calc(100% * (-20) / 720);
        `)}

        ${media.tablet(css`
          padding-top: calc(100% * 336 / 640);
          width: calc(100% * 259 / 640);
          top: inherit;
          left: calc(100% * (-20) / 640);
          bottom: calc(100% * (91) / 533.3);
        `)}
      }

      &:nth-child(5) > div:nth-child(1) > div:nth-child(2) {
        width: calc(100% * 245 / 720);
        top: calc(100% * (-88) / 600);
        right: calc(100% * (-90) / 720);

        ${media.laptop2(css`
          right: calc(100% * (-20) / 720);
        `)}

        ${media.tablet(css`
          padding-top: calc(100% * 336 / 640);
          width: calc(100% * 259 / 640);
          left: inherit;
          top: inherit;
          right: calc(100% * (-20) / 640);
          bottom: calc(100% * (91) / 533.3);
        `)}
      }
    `};
`;

const Image = styled.div<{
  direction: HistoryCardProps['direction'];
  effectiveServices: HistoryCardProps['effectiveServices'];
  wholesale: HistoryCardProps['wholesale'];
  ourSupport: HistoryCardProps['ourSupport'];
}>`
  position: relative;
  flex: 1 1 692px;
  max-width: 692px;

  ${media.tablet(css`
    flex: 1 1 600px;
    max-width: 600px;
  `)}

  ${media.mobile(css`
    flex: auto;
    min-width: 80%;
  `)}

  ${({ direction }) =>
    direction === 'end' &&
    css`
      margin-left: 90px;

      ${media.tablet(css`
        margin-left: 40px;
      `)}

      ${media.mobile(css`
        align-self: flex-end;
        margin-left: 0;
        margin-bottom: 70px;
      `)}
    `};

  ${({ effectiveServices, wholesale, ourSupport }) =>
    (effectiveServices || wholesale || ourSupport) &&
    css`
      margin-left: 0;
      flex: 1 1 720px;
      max-width: 720px;

      ${media.tablet(css`
        flex: auto;
        max-width: 640px;
        margin-left: 0;
      `)}

      ${media.mobile(css`
        align-self: initial;
        margin-bottom: 0;
      `)}
    `};
`;

const Content = styled.div<{
  direction: HistoryCardProps['direction'];
  effectiveServices: HistoryCardProps['effectiveServices'];
  wholesale: HistoryCardProps['wholesale'];
  ourSupport: HistoryCardProps['ourSupport'];
}>`
  flex: 0 0 721px;
  margin-left: 140px;

  ${media.tablet(css`
    flex: 0 0 375px;
    margin-left: 45px;
    margin-bottom: 100px;
  `)}

  ${media.mobile(css`
    flex: auto;
    margin-left: 0;
    margin-bottom: 0;
  `)}

  ${({ direction }) =>
    direction === 'end' &&
    css`
      margin-left: 0;

      ${media.tablet(css`
        margin-left: 0;
      `)}
    `};

  ${({ effectiveServices, wholesale, ourSupport }) =>
    (effectiveServices || wholesale || ourSupport) &&
    css`
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      margin-left: 0;
      background: white;

      ${media.laptop(css`
        flex: 0 0 700px;
      `)}

      ${media.tablet(css`
        margin-left: 0;
        margin-bottom: 0;
        max-width: 640px;
      `)}
      
       ${media.mobile(css`
        flex: auto;
      `)}
    `};

  ${({ wholesale, ourSupport }) =>
    (wholesale || ourSupport) &&
    css`
      background: #f8f8f8;

      ${media.tablet(css`
        background: white;
      `)}

      ${media.mobile(css`
        background: #f8f8f8;
      `)}
    `};

  ${({ ourSupport, direction }) =>
    ourSupport &&
    direction === 'start' &&
    css`
      background: white;
    `};

  ${({ ourSupport }) =>
    ourSupport &&
    css`
      ${media.mobile(css`
        background: white;
      `)}
    `};
`;

const Title = styled.p<{
  wholesale: HistoryCardProps['wholesale'];
  ourSupport: HistoryCardProps['ourSupport'];
  effectiveServices: HistoryCardProps['effectiveServices'];
}>`
  position: relative;
  font-size: 30px;
  line-height: 45px;
  font-weight: 500;

  &:before {
    content: '';
    position: absolute;
    background: #00843d;
    height: 6px;
    width: 120px;
    top: -20px;
  }

  ${media.laptop(css`
    font-size: 25px;
    line-height: 40px;
  `)}

  ${media.tablet(css`
    font-size: 30px;
    line-height: 45px;
  `)}
  
  ${media.mobile(css`
    font-size: 28px;
    line-height: 42px;
  `)}

  ${({ effectiveServices, wholesale, ourSupport }) =>
    (effectiveServices || wholesale || ourSupport) &&
    css`
      &:before {
        background: black;
      }

      ${media.mobile(css`
        margin-top: 45px;
      `)}
    `};
`;

const Text = styled.p<{
  direction: HistoryCardProps['direction'];
  effectiveServices: HistoryCardProps['effectiveServices'];
  wholesale: HistoryCardProps['wholesale'];
  ourSupport: HistoryCardProps['ourSupport'];
}>`
  position: relative;
  font-size: 36px;
  line-height: 54px;

  &:before {
    content: '';
    position: absolute;
    background: #00843d;
    height: 10px;
    width: 248px;
    top: -21px;
  }

  ${media.laptop(css`
    font-size: 30px;
    line-height: 45px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;

    &:before {
      width: 90px;
      height: 5px;
    }
  `)}
  
  ${media.mobile(css`
    &:before {
      width: 150px;
      height: 5px;
    }
  `)}

  ${({ direction }) =>
    direction === 'end' &&
    css`
      &:before {
        background: #ff0000;
        height: 10px;
        width: 183px;
        top: -31px;

        ${media.tablet(css`
          width: 90px;
          height: 5px;
        `)}

        ${media.mobile(css`
          width: 150px;
          height: 5px;
        `)}
      }
    `};

  ${({ effectiveServices, wholesale, ourSupport }) =>
    (effectiveServices || wholesale || ourSupport) &&
    css`
      margin-top: 20px;
      font-size: 24px;
      line-height: 36px;

      &:before {
        content: none;
      }

      ${media.laptop(css`
        font-size: 20px;
        line-height: 30px;
      `)}

      ${media.tablet(css`
        font-size: 24px;
        line-height: 36px;
      `)}
      
      ${media.mobile(css`
        font-size: 16px;
        line-height: 24px;
      `)}
    `};
`;

const BackImage = styled(Picture)<{
  direction: HistoryCardProps['direction'];
  effectiveServices: HistoryCardProps['effectiveServices'];
  wholesale: HistoryCardProps['wholesale'];
  ourSupport: HistoryCardProps['ourSupport'];
}>`
  position: relative;
  padding-top: 122.109%;
  width: 100%;
  margin-bottom: 210px;

  picture {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  ${media.mobile(css`
    margin-bottom: 70px;
  `)}

  ${({ direction }) =>
    direction === 'end' &&
    css`
      margin: 100px 0 0 0;

      ${media.mobile(css`
        margin: 0;
      `)}
    `};

  ${({ effectiveServices, wholesale, ourSupport }) =>
    (effectiveServices || wholesale || ourSupport) &&
    css`
      padding-top: 83.334%;
      width: 100%;
      margin: 0;

      ${media.mobile(css`
        margin-bottom: 0;
      `)}
    `};
`;

const FrontImage = styled(Picture)<{
  direction: HistoryCardProps['direction'];
  effectiveServices: HistoryCardProps['effectiveServices'];
  wholesale: HistoryCardProps['wholesale'];
  ourSupport: HistoryCardProps['ourSupport'];
}>`
  position: absolute !important;
  right: -27%;
  top: 9%;
  padding-top: 48.556%;
  width: 36%;

  picture {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  ${media.tablet(css`
    right: -27%;
    top: -2%;
  `)}

  ${media.mobile(css`
    top: inherit;
    right: -23%;
    bottom: 5%;
  `)}

  ${({ direction }) =>
    direction === 'end' &&
    css`
      right: -12%;
      top: 23%;

      ${media.laptop2(css`
        top: inherit;
        right: inherit;
        left: -20%;
        bottom: 20%;
      `)}

      ${media.tablet(css`
        bottom: -4%;
      `)}
      
      ${media.mobile(css`
        bottom: inherit;
        top: -3%;
        left: -25%;
      `)}
    `};

  ${({ effectiveServices }) =>
    effectiveServices &&
    css`
      left: -23.5%;
      top: calc(100% * 132 / 600);
      padding-top: 46.668%;
      width: 46.668%;

      ${media.laptop2(css`
        left: -2%;
        top: -5%;
      `)}

      ${media.tablet(css`
        width: calc(100% * 259 / 640);
        padding-top: calc(100% * 259 / 640);
        left: calc(100% * (-20) / 640);
        top: calc(100% * (-30) / 533.33);
      `)}
      
      ${media.mobile(css`
        bottom: inherit;
      `)}
    `};

  ${({ wholesale, ourSupport }) =>
    (wholesale || ourSupport) &&
    css`
      top: calc(100% * (-100) / 600);
      right: calc(100% * (-90) / 720);
      padding-top: 46.668%;
      width: calc(100% * 245 / 720);

      ${media.laptop2(css`
        top: calc(100% * (-100) / 600);
        right: calc(100% * (-20) / 720);
        left: inherit;
        bottom: inherit;
      `)}

      ${media.tablet(css`
        width: calc(100% * 259 / 640);
        padding-top: calc(100% * 259 / 640);
        left: calc(100% * (-20) / 640);
        top: calc(100% * (-30) / 533.33);
      `)}
      
      ${media.mobile(css`
        width: calc(100% * 245 / 640);
        padding-top: calc(100% * 336 / 640);
      `)}
    `};

  ${({ ourSupport }) =>
    ourSupport &&
    css`
      top: calc(100% * 132 / 600);

      ${media.laptop2(css`
        top: calc(100% * (132) / 600);
      `)}

      ${media.tablet(css`
        padding-top: calc(100% * 336 / 640);
        left: calc(100% * (-20) / 640);
        top: calc(100% * (-30) / 533.33);
      `)}
    `};
`;
