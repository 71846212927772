import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { TextFieldProps } from './TextField.types';

function TextField(props: TextFieldProps) {
  return <Input {...props} />;
}

export default TextField;

const Input = styled.input`
  width: 100%;
  max-width: 380px;
  height: 80px;
  padding: 25px 35px;
  font-size: 20px;
  line-height: 30px;
  border: none;
  background: #ffffff;

  &::placeholder {
    color: black;
  }

  ${media.laptop(css`
    font-size: 18px;
    line-height: 27px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 27px;
    min-width: 420px;
  `)}
  
  ${media.mobile(css`
    min-width: inherit;
  `)}
`;
