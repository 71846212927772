import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import SwiperCore, { Pagination } from 'swiper';
import styled from 'styled-components';
import chunk from 'lodash/chunk';

import OurValueCard from '@/modules/Home/components/OurValueCard';
import { useSourcingActivityData } from '@/modules/SourcingActivity/SourcingActivity.hooks';

SwiperCore.use([Pagination]);

function OurFeaturesSlider() {
  const { ourFeaturesItems } = useSourcingActivityData();
  const ourFeaturesTriplesItems = chunk(ourFeaturesItems, 3);

  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) =>
      `<span class="${className}">${index + 1}</span>`,
  };

  return (
    <Component>
      <Swiper pagination={pagination}>
        {ourFeaturesTriplesItems.map((items, index) => (
          <SwiperSlide key={index}>
            {items.map(({ image, description }, index) => (
              <OurValueCard
                key={index}
                image={image}
                description={description ?? ''}
                ourFeatures={true}
              />
            ))}
          </SwiperSlide>
        ))}
      </Swiper>
    </Component>
  );
}

export default OurFeaturesSlider;

const Component = styled.div`
  margin-top: 50px;

  .swiper {
    padding-bottom: 60px;

    &-pagination {
      display: flex;
      align-items: center;
      justify-content: center;

      &-bullet {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 17px;
        height: 17px;
        border-radius: 0;
        color: white;
        background: black;

        &-active {
          width: 20px;
          height: 20px;
        }
      }
    }

    &-wrapper > .swiper-slide > :not(:first-child) {
      margin-top: 20px;
    }
  }
`;
