import React from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';

import arrow from '../../../assets/svg/arrow-right-white.svg';

import { ButtonWithArrowProps } from './ButtonWithArrow.types';

export function ButtonWithArrow({
  title,
  buttonLinkMode,
  to,
  findContainer,
  sourcingAgent,
  easyStart,
  isLoading,
}: ButtonWithArrowProps) {
  return buttonLinkMode ? (
    <ButtonLink to={to} sourcingAgent={sourcingAgent} easyStart={easyStart}>
      {title}
      <Icon src={arrow} />
    </ButtonLink>
  ) : (
    <Button type="submit" findContainer={findContainer} disabled={isLoading}>
      {title}
      <Icon src={arrow} />
    </Button>
  );
}

const Button = styled.button<{
  findContainer: ButtonWithArrowProps['findContainer'];
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: black;
  border: none;
  color: white;
  padding: 25px 35px;
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;
  width: 100%;
  max-width: 457px;
  transition: 0.3s;

  &:hover {
    transition: 0.3s;
    background: #ff0000;
  }

  ${media.laptop(css`
    font-size: 18px;
    line-height: 28px;
  `)}

  ${media.tablet(css`
    max-width: 420px;
    font-size: 20px;
    line-height: 30px;
  `)}

  ${({ findContainer }) =>
    findContainer &&
    css`
      background: red;
      min-width: 258px;
      padding: 25.2px 35px;

      &:hover {
        transition: 0.3s;
        background: #bd0202;
      }
    `}
`;

const ButtonLink = styled(Link)<{
  sourcingAgent: ButtonWithArrowProps['sourcingAgent'];
  easyStart: ButtonWithArrowProps['easyStart'];
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: black;
  border: none;
  color: white;
  padding: 25px 35px;
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;
  width: 100%;
  max-width: 457px;
  transition: 0.3s;

  &:hover {
    transition: 0.3s;
    background: #ff0000;
  }

  ${media.laptop(css`
    min-width: 300px;
    font-size: 18px;
    line-height: 28px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;
  `)}

  ${({ sourcingAgent }) =>
    sourcingAgent &&
    css`
      background: red;
      max-width: 345px;
      padding: 25.2px 35px;

      &:hover {
        transition: 0.3s;
        background: #bd0202;
      }
    `}

  ${({ easyStart }) =>
    easyStart &&
    css`
      background: red;
      min-width: 260px;
      padding: 25.2px 35px;

      &:hover {
        transition: 0.3s;
        background: #bd0202;
      }

      ${media.laptop(css`
        min-width: 260px;
      `)}
    `}
`;

const Icon = styled(Picture)`
  img {
    width: 40px;
    height: 15px;
  }
`;
