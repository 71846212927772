import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import SectionHeader from '@/components/SectionHeader';
import HistoryCard from '@/modules/Home/components/HistoryCard';
import { useSourcingActivityData } from '@/modules/SourcingActivity/SourcingActivity.hooks';
import { media } from '@/utils/mixin';

function OurSupport() {
  const { ourSupportTitle, ourSupportItems } = useSourcingActivityData();

  return (
    <Component>
      <ContentContainer>
        <Header>
          <SectionHeader title={ourSupportTitle ?? ''} />
        </Header>

        <Cards>
          {ourSupportItems.map(
            ({ title, description, backImage, frontImage }, index: number) => (
              <HistoryCard
                title={title ?? ''}
                text={description ?? ''}
                backImage={backImage}
                frontImage={frontImage}
                direction={(index + 1) % 2 === 1 ? 'end' : 'start'}
                ourSupport={true}
                key={index}
              />
            )
          )}
        </Cards>
      </ContentContainer>
    </Component>
  );
}

export default OurSupport;

const Component = styled.section`
  background: white;
`;

const Header = styled.div`
  margin-top: 175px;

  ${media.mobile(css`
    margin-top: 100px;
  `)}
`;

const Cards = styled.div`
  margin: 5px 0 350px 0;

  ${media.tablet(css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 0 168px 0;
  `)}
`;
