import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import OurCompanyCard from '@/modules/Home/components/OurCompanyCard';
import { useWholesaleData } from '@/modules/Wholesale/Wholesale.hooks';
import { media } from '@/utils/mixin';

function UnderCover() {
  const { underCoverCards } = useWholesaleData();

  return (
    <Component>
      <ContentContainer>
        <Cards>
          {underCoverCards.map(({ image, description }, index: number) => (
            <OurCompanyCard
              image={image}
              description={description ?? ''}
              wholesale={true}
              key={index}
            />
          ))}
        </Cards>
      </ContentContainer>
    </Component>
  );
}

export default UnderCover;

const Component = styled.section`
  background: black;
`;

const Cards = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(5, 1fr);
  gap: 0 20px;

  ${media.tablet(css`
    position: relative;
    z-index: 2;
    grid-template-columns: repeat(2, 320px);
    gap: 20px 20px;
    transform: translate(0, -155px);
  `)}

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
    transform: translate(0, -20px);
  `)}
`;
