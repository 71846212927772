import React from 'react';
import styled from 'styled-components';

import Cover from '@/components/Cover';
import EffectiveServices from '@/modules/Logistics/components/EffectiveServices';
import FollowingServices from '@/modules/Logistics/components/FollowingServices';
import FindContainer from '@/modules/Logistics/components/FindContainer';
import { useLogisticsData } from '@/modules/Logistics/Logistics.hooks';

function Logistics() {
  const { title, image, findContainerImage, findContainerTitle } =
    useLogisticsData();

  return (
    <Component>
      <Cover
        title={title ?? ''}
        image={image}
        coverCards={true}
        logistics={true}
      />
      <EffectiveServices />
      <FollowingServices />
      <FindContainer
        image={findContainerImage}
        title={findContainerTitle ?? ''}
      />
    </Component>
  );
}

export default Logistics;

const Component = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
