import * as Yup from 'yup';

import { ContactFormData } from './ContactForm.types';

export const validationSchema: Yup.SchemaOf<ContactFormData> =
  Yup.object().shape({
    name: Yup.string().label('Name').min(2).max(50).required(),
    email: Yup.string().label('Email').email().required(),
    message: Yup.string().label('Message').min(0).max(320).required(),
  });
