import React from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

import { EasyStepsCardType } from './EasyStepsCard.types';

function EasyStepsCard({
  title,
  description,
  image,
  cardNumber,
  whiteCard,
}: EasyStepsCardType) {
  return (
    <Card>
      <Background
        src={image.url}
        src2x={image.url_2x}
        srcWebp={image.url_webp}
        srcWebp2x={image.url_webp_2x}
        whiteCard={whiteCard}
      />
      <Content>
        <Number whiteCard={whiteCard}>
          <Index>{cardNumber}</Index>
        </Number>
        <Title whiteCard={whiteCard}>{title}</Title>
        <Description whiteCard={whiteCard}>{description}</Description>
      </Content>
    </Card>
  );
}

export default EasyStepsCard;

const Card = styled.div`
  position: relative;
  transform: skew(-4deg);

  &:first-child {
    &:before {
      content: '';
      position: absolute;
      background: #f0f0f0;
      width: 100%;
      height: 100%;
      left: calc(100% * (-550) / 540);
    }
  }

  &:nth-child(2) {
    transform: skew(-4deg) translateY(-50px);

    ${media.laptop(css`
      transform: skew(-3deg) translateY(-50px);
    `)}
  }

  &:nth-child(3) {
    transform: skew(-4deg) translateY(-100px);

    &:before {
      content: '';
      position: absolute;
      background: black;
      width: 100%;
      height: 100%;
      right: calc(100% * (-550) / 540);
    }

    ${media.laptop(css`
      transform: skew(-3deg) translateY(-100px);
    `)}
  }

  ${media.laptop(css`
    transform: skew(-3deg);
  `)}

  ${media.tablet(css`
    &:nth-child(n) {
      transform: skew(0deg, 4deg);

      &:before {
        content: none;
      }
    }
  `)}
`;

const Background = styled(Picture)<{
  whiteCard: EasyStepsCardType['whiteCard'];
}>`
  position: absolute !important;
  height: 100%;
  width: 100%;
  filter: grayscale(100%);

  picture {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.54) 9.06%,
      #000000 99.9%
    );
  }

  ${({ whiteCard }) =>
    whiteCard &&
    css`
      &:after {
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.54) 0%,
          #f9f9f9 43.25%
        );
      }
    `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px 40px;
  position: relative;
  z-index: 2;
  transform: skew(4deg);

  ${media.laptop(css`
    padding: 40px 20px;
    transform: skew(3deg);
  `)}

  ${media.tablet(css`
    padding: 62px 40px;
    transform: skew(0deg, -4deg);
  `)}
  
  ${media.mobile(css`
    padding: 62px 20px;
  `)}
`;

const Number = styled.div<{
  whiteCard: EasyStepsCardType['whiteCard'];
}>`
  position: relative;
  padding: 27.5px 35.5px;
  background: #ff0000;

  ${media.laptop(css`
    padding: 23px 31px;
  `)}

  ${({ whiteCard }) =>
    whiteCard &&
    css`
      background: black;
    `}
`;

const Index = styled.span`
  font-size: 30px;
  line-height: 45px;
  color: white;

  ${media.laptop(css`
    font-size: 25px;
    line-height: 40px;
  `)}

  ${media.mobile(css`
    font-size: 30px;
    line-height: 45px;
  `)}
`;

const Title = styled(Index)<{
  whiteCard: EasyStepsCardType['whiteCard'];
}>`
  position: relative;
  margin-top: 49px;
  color: white;

  ${({ whiteCard }) =>
    whiteCard &&
    css`
      color: black;
    `}

  ${media.tablet(css`
    font-size: 30px;
    line-height: 45px;
  `)}
`;

const Description = styled.p<{
  whiteCard: EasyStepsCardType['whiteCard'];
}>`
  position: relative;
  margin-top: 20px;
  font-size: 18px;
  line-height: 27px;
  color: white;

  ${({ whiteCard }) =>
    whiteCard &&
    css`
      color: black;
    `}

  ${media.laptop(css`
    font-size: 16px;
    line-height: 25px;
  `)}
  
   ${media.tablet(css`
    max-width: 700px;
    font-size: 20px;
    line-height: 30px;
  `)}
   
   ${media.mobile(css`
    font-size: 16px;
    line-height: 24px;
  `)}
`;
