import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import SectionHeader from '@/components/SectionHeader';
import { useHomeData } from '@/modules/Home/Home.hooks';
import { media } from '@/utils/mixin';

import OurValueCard from '../OurValueCard';

function OurValues() {
  const { ourValuesTitle, ourValues } = useHomeData();

  return (
    <Component>
      <ContentContainer>
        <Header>
          <SectionHeader title={ourValuesTitle ?? ''} />
        </Header>
        <Cards>
          {ourValues.map(({ image, title, description }, index: number) => (
            <OurValueCard
              image={image}
              title={title ?? ''}
              description={description ?? ''}
              imageAbsolute={index === 1}
              key={index}
            />
          ))}
        </Cards>
      </ContentContainer>
    </Component>
  );
}

export default OurValues;

const Component = styled.section`
  background: linear-gradient(360deg, #fff 0%, #e8eaed 100%);
`;

const Header = styled.div`
  margin-top: 104px;

  ${media.mobile(css`
    & > div {
      justify-content: flex-start;
    }
  `)}
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 115px;
  margin: 100px 0 138px 0;

  ${media.tablet(css`
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  `)}

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
    gap: 50px;
    margin-top: 50px;
  `)}
`;
