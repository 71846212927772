import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { CoverCardProps } from './CoverCard.types';

function CoverCard({ text }: CoverCardProps) {
  return (
    <Card>
      <Text dangerouslySetInnerHTML={{ __html: text }} />
    </Card>
  );
}

export default CoverCard;

const Card = styled.div`
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-right: 60px;
    padding-right: 60px;
    border-right: 2px solid #333;

    ${media.tablet(css`
      margin-right: 0;
      padding-right: 0;
      border-right: none;
    `)}
  }

  &:not(:first-child) {
    ${media.tablet(css`
      margin-top: 80px;

      & > div:before {
        content: '';
        position: absolute;
        top: -40px;
        width: 135px;
        height: 2px;
        background: black;
      }
    `)}
  }
`;

const Text = styled.div`
  position: relative;
  font-size: 18px;
  line-height: 27px;

  ${media.laptop(css`
    font-size: 15px;
    line-height: 24px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 27px;
  `)}
  
  ${media.mobile(css`
    font-size: 15px;
    line-height: 22px;
  `)}
`;
