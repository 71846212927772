import React from 'react';
import styled from 'styled-components';

import { TextFieldProps } from './TextField.types';

function TextField({ errorMessage, ...props }: TextFieldProps) {
  return (
    <Component>
      <Input {...props} />
      {errorMessage && <Error>{errorMessage}</Error>}
    </Component>
  );
}

export default TextField;

const Component = styled.div`
  position: relative;
  margin-top: 19px;

  &:nth-child(1) {
    margin-top: 0;
  }
`;

const Input = styled.input`
  display: block;
  width: 100%;
  height: 80px;
  padding: 21px 35px;
  font-size: 18px;
  line-height: 27px;
  border: none;
  background: #e8eaed;

  &::placeholder {
    color: black;
  }
`;

const Error = styled.div`
  position: absolute;
  color: red;
  font-size: 15px;
  line-height: 18px;
`;
