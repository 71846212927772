import React from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

import { OurValueCardProps } from './OurValueCard.types';

function OurValueCard({
  image,
  title,
  description,
  imageAbsolute,
  followingServices,
  ourAdvantages,
  sourcingActivity,
  ourFeatures,
}: OurValueCardProps) {
  return (
    <Card
      followingServices={followingServices}
      ourAdvantages={ourAdvantages}
      sourcingActivity={sourcingActivity}
      ourFeatures={ourFeatures}
    >
      {imageAbsolute ? (
        <CardImageAbsolute
          src={image.url}
          src2x={image.url_2x}
          srcWebp={image.url_webp}
          srcWebp2x={image.url_webp_2x}
        />
      ) : (
        <CardImage
          followingServices={followingServices}
          ourAdvantages={ourAdvantages}
          sourcingActivity={sourcingActivity}
          ourFeatures={ourFeatures}
          src={image.url}
          src2x={image.url_2x}
          srcWebp={image.url_webp}
          srcWebp2x={image.url_webp_2x}
        />
      )}
      {title && <CardTitle>{title}</CardTitle>}
      <CardDescription
        followingServices={followingServices}
        ourAdvantages={ourAdvantages}
        sourcingActivity={sourcingActivity}
        ourFeatures={ourFeatures}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Card>
  );
}

export default OurValueCard;

const Card = styled.div<{
  followingServices: OurValueCardProps['followingServices'];
  ourAdvantages: OurValueCardProps['ourAdvantages'];
  sourcingActivity: OurValueCardProps['sourcingActivity'];
  ourFeatures: OurValueCardProps['ourFeatures'];
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  &:nth-child(3) > div picture img {
    width: 83px;
    height: 119px;
  }

  ${({ followingServices }) =>
    followingServices &&
    css`
      flex-direction: row;
      align-items: center;

      &:nth-child(3) > div picture img {
        width: 90px;
        height: 90px;
      }

      ${media.tablet(css`
        flex-direction: column;
        align-items: flex-start;
      `)}

      ${media.mobile(css`
        align-items: center;
      `)}
    `};

  ${({ ourAdvantages }) =>
    ourAdvantages &&
    css`
      align-items: center;
      &:nth-child(3) > div picture img {
        width: 160px;
        height: 160px;
      }

      ${media.mobile(css`
        &:nth-child(3) > div picture img {
          width: 125px;
          height: 125px;
        }
      `)}
    `};

  ${({ sourcingActivity }) =>
    sourcingActivity &&
    css`
      padding: 22.5px 40px 60px 40px;
      background: #292929;

      &:nth-child(odd) {
        background: #151515;
      }

      &:nth-child(n) > div picture img {
        width: 116px;
        height: 114px;
      }

      &:nth-child(1) {
        transform: translate(0, 50px);
      }

      &:nth-child(2) {
        transform: translate(0, -41px);
      }

      &:nth-child(3) {
        transform: translate(0, -131px);

        & > div picture img {
          width: 84px;
          height: 100px;
        }
      }

      ${media.tablet(css`
        &:nth-child(n) {
          transform: translate(0, 0);
        }
      `)}

      ${media.mobile(css`
        padding: 22.5px 20px 50px 20px;
      `)}
    `};

  ${({ ourFeatures }) =>
    ourFeatures &&
    css`
      align-items: flex-start;
      padding: 20px;

      &:nth-child(3) > div picture img {
        width: 90px;
        height: 90px;
      }

      ${media.tablet(css`
        padding: 0;
      `)}

      ${media.mobile(css`
        align-items: center;
      `)}
    `};
`;

const CardImage = styled(Picture)<{
  followingServices: OurValueCardProps['followingServices'];
  ourAdvantages: OurValueCardProps['ourAdvantages'];
  sourcingActivity: OurValueCardProps['sourcingActivity'];
  ourFeatures: OurValueCardProps['ourFeatures'];
}>`
  position: relative;
  width: 189px;
  height: 115px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 189px;
    height: 115px;
  }

  ${({ followingServices }) =>
    followingServices &&
    css`
      width: 90px;
      height: 90px;

      img {
        width: 90px;
        height: 90px;
      }
    `}

  ${({ ourAdvantages }) =>
    ourAdvantages &&
    css`
      width: 160px;
      height: 160px;

      img {
        width: 160px;
        height: 160px;
      }

      ${media.mobile(css`
        width: 125px;
        height: 125px;

        img {
          width: 125px;
          height: 125px;
        }
      `)}
    `};

  ${({ sourcingActivity }) =>
    sourcingActivity &&
    css`
      width: 116px;
      height: 114px;

      img {
        width: 116px;
        height: 114px;
      }
    `};

  ${({ ourFeatures }) =>
    ourFeatures &&
    css`
      width: 90px;
      height: 90px;

      img {
        width: 90px;
        height: 90px;
      }
    `};
`;

const CardImageAbsolute = styled(Picture)`
  position: relative;
  width: 189px;
  height: 115px;

  img {
    position: absolute;
    min-width: 240px;
    min-height: 240px;
    top: -25px;
    left: -25px;
  }
`;

const CardTitle = styled.span`
  margin-top: 32px;
  font-size: 36px;
  line-height: 54px;
  font-weight: 500;
  text-transform: uppercase;
`;

const CardDescription = styled.div<{
  followingServices: OurValueCardProps['followingServices'];
  ourAdvantages: OurValueCardProps['ourAdvantages'];
  sourcingActivity: OurValueCardProps['sourcingActivity'];
  ourFeatures: OurValueCardProps['ourFeatures'];
}>`
  margin-top: 16px;
  font-size: 18px;
  line-height: 27px;

  ${media.mobile(css`
    max-width: 500px;
  `)}

  ${({ followingServices }) =>
    followingServices &&
    css`
      margin-top: 0;
      margin-left: 20px;
      font-size: 20px;
      line-height: 30px;
      max-width: 340px;

      ${media.laptop(css`
        font-size: 16px;
        line-height: 26px;
        max-width: 260px;
      `)}

      ${media.tablet(css`
        margin-top: 15px;
        margin-left: 0;
        font-size: 20px;
        line-height: 30px;
      `)}
      
      ${media.mobile(css`
        text-align: center;
      `)}
    `};

  ${({ ourAdvantages }) =>
    ourAdvantages &&
    css`
      margin-top: 10px;
      text-align: center;

      ${media.mobile(css`
        font-size: 16px;
        line-height: 24px;
      `)}
    `};

  ${({ sourcingActivity }) =>
    sourcingActivity &&
    css`
      margin-top: 23px;
      text-align: left;
      color: white;

      ${media.laptop(css`
        font-size: 16px;
        line-height: 24px;
      `)}

      ${media.tablet(css`
        font-size: 18px;
        line-height: 27px;
      `)}
      
      ${media.mobile(css`
        font-size: 16px;
        line-height: 24px;
      `)}
    `};

  ${({ ourFeatures }) =>
    ourFeatures &&
    css`
      margin-top: 15px;
      font-size: 20px;
      line-height: 30px;

      ${media.tablet(css`
        max-width: 320px;
      `)}

      ${media.mobile(css`
        text-align: center;
      `)}
    `};
`;
