import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import HistoryCard from '@/modules/Home/components/HistoryCard';
import { useWholesaleData } from '@/modules/Wholesale/Wholesale.hooks';
import { media } from '@/utils/mixin';

function BottomCards() {
  const {
    firstCardTitle,
    firstCardDescription,
    firstCardBackImage,
    firstCardFrontImage,
    secondCardTitle,
    secondCardDescription,
    secondCardBackImage,
  } = useWholesaleData();

  return (
    <Component>
      <ContentContainer>
        <Cards>
          <HistoryCard
            title={firstCardTitle ?? ''}
            text={firstCardDescription ?? ''}
            backImage={firstCardBackImage ?? ''}
            frontImage={firstCardFrontImage ?? ''}
            direction="end"
            wholesale={true}
          />
          <HistoryCard
            title={secondCardTitle ?? ''}
            text={secondCardDescription ?? ''}
            backImage={secondCardBackImage ?? ''}
            direction="start"
            wholesale={true}
          />
        </Cards>
      </ContentContainer>
    </Component>
  );
}

export default BottomCards;

const Component = styled.section`
  background: white;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 200px;

  ${media.mobile(css`
    margin-bottom: 100px;
  `)}
`;
